import React, { useState, useEffect } from 'react';
import {
  Paper,
  Typography,
  Grid,
  Box,
  Button,
  LinearProgress,
  IconButton,
  Tooltip, // Add Tooltip here
} from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, ResponsiveContainer, Legend,
} from 'recharts';
import { makeStyles } from '@material-ui/core/styles';
import { Phone, AccessTime, CheckCircle, Error, PieChart as PieIcon } from '@material-ui/icons';
import axios from 'axios';
import InfoIcon from '@mui/icons-material/Info';


// Define colors
const COLORS = ['#A3BFFA', '#68D391', '#FEB2B2', '#FC8181', '#B794F4'];
const BLOCK_COLORS = ['#E0ECF8', '#F0EBFA'];

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: '#F7FAFC',
  },
  paper: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign: 'center',
    borderRadius: '8px',
    backgroundColor: '#EDF2F7',
    boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
  },
  block: {
    padding: theme.spacing(1),
    color: '#2D3748',
    fontSize: '0.75rem',
    fontWeight: 500,
  },
  chartContainer: {
    width: '100%',
    height: 300,
  },
  icon: {
    fontSize: '1.2rem',
    color: '#718096',
    marginRight: theme.spacing(1),
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
  paginationButton: {
    margin: theme.spacing(0.5),
    padding: theme.spacing(0.5),
    minWidth: '30px',
    fontSize: '0.75rem',
    color: '#2D3748',
  },
  blockColors: {
    backgroundColor: (index) => BLOCK_COLORS[index % 2],
  },
  boldText: {
    fontWeight: 'bold',
    fontSize: '1.1rem',
  },
  overviewTitle: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
  },
  dataGrid: {
    border: '1px solid #E2E8F0', // Add border for better visibility
    '& .MuiDataGrid-cell': {
      textAlign: 'center',
    },
    '& .MuiDataGrid-row': {
      
    color:'black',
    fontWeight: 'bold',
      backgroundColor: '#fff',
      '&:nth-of-type(odd)': {
        backgroundColor: '#EDF2F7',
      },
    },
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: '#A0AEC0',
      color: '#fff',
    },
  },
}));

const HomePage = () => {
  const classes = useStyles();
  const [campaignData, setCampaignData] = useState([]);
  const [overallData, setOverallData] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const campaignsPerPage = 5;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post('https://app.glimpass.com/exotel/get-client-overview', {
          campaignId: 'all campaign',
          clientId: localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth'))._id : ''
        });
        setCampaignData(response.data.campaigns || []);
        setOverallData(response.data.overall || {});
      } catch (error) {
        console.error('Error fetching data:', error);
        setCampaignData([]);
        setOverallData({});
      }
    };
    fetchData();
  }, []);

  const handleNextPage = () => {
    if ((currentPage + 1) * campaignsPerPage < campaignData.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const displayData = campaignData.slice(currentPage * campaignsPerPage, (currentPage + 1) * campaignsPerPage);

  const columns = [
    { field: 'campaignId', headerName: 'Campaign ID', width: 30, flex: 1},  //  // Flex layout for dynamic resizing
    { field: 'hotLead', headerName: 'Hot', width: 15 },
    { field: 'warmLead', headerName: 'Warm', width: 25 },
    { field: 'coldLead', headerName: 'Cold', width: 15 },
    // {
    //   field: 'info',
    //   headerName: 'Details',
    //   renderCell: () => (
    //     <Tooltip title="More details about the campaign">
    //       <IconButton>
    //         <InfoIcon className={classes.infoIcon} />
    //       </IconButton>
    //     </Tooltip>
    //   ),
    //   width: 100,
    // },
  ];

  return (
    <div className={classes.root}>
      <Typography className={classes.overviewTitle} gutterBottom>
        Analytics Dashboard
      </Typography>

      {/* Overall Metrics */}
      <Grid container spacing={1}>
        {['Total AI Calls', 'Total Time', 'Completed', 'Failed', 'Hot/Warm/Cold', 'Avg. Duration'].map((label, index) => (
          <Grid item xs={6} md={2} key={index}>
            <Paper className={`${classes.paper} ${classes.blockColors}`} style={{ backgroundColor: BLOCK_COLORS[index % 2] }}>
              <Box display="flex" alignItems="center">
                {label === 'Total AI Calls' && <Phone className={classes.icon} />}
                {label === 'Total Time' && <AccessTime className={classes.icon} />}
                {label === 'Completed' && <CheckCircle className={classes.icon} />}
                {label === 'Failed' && <Error className={classes.icon} />}
                {label === 'Hot/Warm/Cold' && <PieIcon className={classes.icon} />}
                {label === 'Avg. Duration' && <AccessTime className={classes.icon} />}
                <Typography className={classes.block}>{label}</Typography>
              </Box>
              <Typography variant="h6" className={classes.boldText}>
                {label === 'Total AI Calls' ? overallData.totalData || 0 :
                 label === 'Total Time' ? `${(overallData.totalDuration / 60).toFixed(2)} Min` :
                 label === 'Completed' ? overallData.completed || 0 :
                 label === 'Failed' ? overallData.failed || 0 :
                 label === 'Hot/Warm/Cold' ? `${overallData.hotLead || 0}/${overallData.warmLead || 0}/${overallData.coldLead || 0}` :
                 overallData.avgDuration ? overallData.avgDuration.toFixed(2) : 0}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>

      {/* Campaign Insights */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Paper className={classes.paper}>
            <Typography className={classes.block}>Campaign Insights (Hot/Warm/Cold)</Typography>
            <DataGrid
              rows={displayData}
              columns={columns}
              pageSize={5}
              autoHeight
              disableSelectionOnClick
              className={classes.dataGrid}
              getRowId={(row) => row.campaignId}
            />
          </Paper>
        </Grid>

        {/* Bar Chart: Campaign Completed vs Failed */}
        <Grid item xs={12} md={6}>
          <Paper className={classes.paper}>
            <Typography className={classes.block}>Campaign Performance (Page {currentPage + 1})</Typography>
            {displayData.length > 0 ? (
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={displayData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="campaignId" />
                  <YAxis />
                  <RechartsTooltip />
                  <Legend />
                  <Bar dataKey="completed" stackId="a" fill={COLORS[1]} name="Completed" />
                  <Bar dataKey="failed" stackId="a" fill={COLORS[3]} name="Failed" />
                </BarChart>
              </ResponsiveContainer>
            ) : (
              <Typography>No data available for Campaign Performance.</Typography>
            )}
          </Paper>
        </Grid>
      </Grid>

      {/* Unified Pagination for Campaign Insights and Performance */}
      <Box className={classes.paginationContainer}>
        <Button onClick={handlePreviousPage} disabled={currentPage === 0} className={classes.paginationButton}>
          Prev
        </Button>
        <Button
          onClick={handleNextPage}
          disabled={(currentPage + 1) * campaignsPerPage >= campaignData.length}
          className={classes.paginationButton}
        >
          Next
        </Button>
      </Box>
    </div>
  );
};

export default HomePage;
