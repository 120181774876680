import axios from 'axios';

// Action types
export const FETCH_BILLING_HISTORY_SUCCESS = 'FETCH_BILLING_HISTORY_SUCCESS';
export const FETCH_CLIENT_SUCCESS = 'FETCH_CLIENT_SUCCESS';
export const FETCH_BILLING_ERROR = 'FETCH_BILLING_ERROR';
export const FETCH_CLIENT_ERROR = 'FETCH_CLIENT_ERROR';

// Fetch billing history action
export const fetchBillingHistory = (clientId) => async (dispatch) => {
    try {
        const response = await axios.post('https://app.glimpass.com/exotel/get-billing-history-by-clientId', {
            clientId
        });
        dispatch({ type: FETCH_BILLING_HISTORY_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({ type: FETCH_BILLING_ERROR, payload: error.message });
    }
};

// Fetch client data action
export const fetchClientData = (clientId) => async (dispatch) => {
    try {
        const response = await axios.post('https://app.glimpass.com/interlogue/get-client-by-clientId', {
            clientId
        });
        dispatch({ type: FETCH_CLIENT_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({ type: FETCH_CLIENT_ERROR, payload: error.message });
    }
};
