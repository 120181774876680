import React, { useState, useEffect } from 'react';
import {
  Box, TextField, Button, Typography, FormControl, Select, MenuItem, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Paper, CircularProgress, Dialog, DialogContent, DialogTitle, Avatar, IconButton, Tooltip,
  Pagination
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: '#333',
    color: '#fff',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  filterSection: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  filterItem: {
    flex: '1 1 15%',
    minWidth: '200px',
  },
  table: {
    backgroundColor: '#333',
  },
  tableHeadCell: {
    color: '#fff',
    fontWeight: 'bold',
    backgroundColor: '#000',
  },
  tableCell: {
    color: 'white',
    backgroundColor: '#333',
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50vh',
    textAlign: 'center',
  },
  chatContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: theme.spacing(2),
  },
  chatMessageBox: {
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(1),
    maxWidth: '75%',
  },
  aiMessage: {
    backgroundColor: '#DCF8C6',
    color: '#000',
    textAlign: 'left',
  },
  clientMessage: {
    backgroundColor: '#FFF',
    color: '#000',
    textAlign: 'left',
    marginLeft: 'auto',
  },
  avatar: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    width: 24,
    height: 24,
  },
  paginationContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  }
}));

const IncomingCalls = () => {
  const classes = useStyles();
  const [callNumbers, setCallNumbers] = useState([]);
  const [selectedNumber, setSelectedNumber] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [incomingCalls, setIncomingCalls] = useState([]);
  const [filteredCalls, setFilteredCalls] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [callsPerPage] = useState(15); // Display 5 calls per page
  const [chatModalOpen, setChatModalOpen] = useState(false);
  const [selectedChat, setSelectedChat] = useState([]);
  const [selectedChatExplanation, setSelectedChatExplanation] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const authData = JSON.parse(localStorage.getItem('auth'));
    const numbers = authData?.callerNumbers?.map((num) => num.startsWith('+91') ? `0${num.slice(3)}` : num);
    setCallNumbers(numbers || []);
  }, []);

  const handleNumberChange = (e) => {
    setSelectedNumber(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleFilter = () => {
    let filtered = incomingCalls;

    // Search by CallSid or phone numbers
    if (searchInput) {
      filtered = filtered.filter(
        (call) =>
          call.CallSid.toLowerCase().includes(searchInput.toLowerCase()) ||
          call.CallFrom.includes(searchInput) ||
          call.CallTo.includes(searchInput)
      );
    }

    // Filter by selected number
    if (selectedNumber) {
      filtered = filtered.filter(
        (call) => call.CallFrom === selectedNumber || call.CallTo === selectedNumber
      );
    }

    // Filter by selected date
    if (selectedDate) {
      filtered = filtered.filter(
        (call) => new Date(call.Created).toLocaleDateString() === new Date(selectedDate).toLocaleDateString()
      );
    }

    setFilteredCalls(filtered);
  };

  const fetchIncomingCalls = async () => {
    setLoading(true);
    setErrorMessage(''); // Clear previous error message

    try {
      const response = await fetch('https://app.glimpass.com/exotel/get-incoming-call-details', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ phoneNumber: selectedNumber || '' })
      });

      const result = await response.json();

      if (response.status === 404 || !Array.isArray(result)) {
        setErrorMessage(result.message || 'No incoming calls found.');
        setIncomingCalls([]);
        setFilteredCalls([]); // Clear previous filtered data
      } else {
        setIncomingCalls(result);
        setFilteredCalls(result); // Set initial calls to be displayed
      }
    } catch (error) {
      console.error('Error fetching incoming calls:', error);
      setErrorMessage('An error occurred while fetching call data.');
    } finally {
      setLoading(false);
    }
  };

  const handleChatOpen = (call) => {
    if (call.chat) {
      const chatMessages = call.chat.split('|').map((message) => message.trim());
      setSelectedChat(chatMessages);
      setSelectedChatExplanation(call.Stream?.DisconnectedBy || '');
      setChatModalOpen(true);
    }
  };

  const handleChatClose = () => {
    setChatModalOpen(false);
    setSelectedChat([]);
  };

  const formatChatMessage = (message) => {
    if (message.startsWith('AI:')) {
      return { sender: 'AI', text: message.replace(/^AI:/, '').trim() };
    } else if (message.startsWith('human:')) {
      return { sender: 'User', text: message.replace(/^human:/, '').trim() };
    } else {
      return { sender: 'Unknown', text: message.trim() };
    }
  };

  const handleCopyToClipboard = (callSid) => {
    navigator.clipboard.writeText(callSid).then(() => {
      alert('Call SID copied to clipboard!');
    });
  };

  const indexOfLastCall = currentPage * callsPerPage;
  const indexOfFirstCall = indexOfLastCall - callsPerPage;
  const currentCalls = filteredCalls.slice(indexOfFirstCall, indexOfLastCall);

  const handlePageChange = (event, pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.filterSection}>
        <TextField
          label="Search by SID or Number"
          variant="outlined"
          value={searchInput}
          onChange={handleSearchChange}
          className={classes.filterItem}
        />
        <FormControl variant="outlined" className={classes.filterItem}>
          <Select value={selectedNumber} onChange={handleNumberChange} displayEmpty>
            <MenuItem value="" disabled>Select Caller Number</MenuItem>
            {callNumbers.map((number, index) => (
              <MenuItem key={index} value={number}>{number}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Filter by Date"
            value={selectedDate}
            onChange={handleDateChange}
            renderInput={(params) => <TextField {...params} className={classes.filterItem} />}
          />
        </LocalizationProvider>
        <Button variant="contained" color="primary" onClick={fetchIncomingCalls} className={classes.filterItem}>
          Fetch Data
        </Button>
        <Button variant="contained" color="secondary" onClick={handleFilter} className={classes.filterItem}>
          Filter
        </Button>
      </Box>

      {loading ? (
        <Box className={classes.centered}>
          <CircularProgress color="inherit" />
          <Typography variant="h6" color="inherit">Loading incoming calls...</Typography>
        </Box>
      ) : errorMessage ? (
        <Box className={classes.centered}>
          <Typography variant="h6" color="error">{errorMessage}</Typography>
        </Box>
      ) : (
        Array.isArray(currentCalls) && currentCalls.length > 0 && (
          <>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="incoming calls table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHeadCell}>Call SID</TableCell>
                    <TableCell className={classes.tableHeadCell}>From</TableCell>
                    <TableCell className={classes.tableHeadCell}>To</TableCell>
                    <TableCell className={classes.tableHeadCell}>Status</TableCell>
                    <TableCell className={classes.tableHeadCell}>On Call Duration</TableCell>
                    <TableCell className={classes.tableHeadCell}>Recording</TableCell>
                    <TableCell className={classes.tableHeadCell}>Date Created</TableCell>
                    <TableCell className={classes.tableHeadCell}>Chat</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentCalls.map((call) => (
                    <TableRow key={call._id}>
                      <TableCell className={classes.tableCell}>
                        <Box display="flex" alignItems="center">
                          <Typography>
                            {call.CallSid.slice(0, 5)}...
                          </Typography>
                          <Tooltip title="Copy to clipboard">
                            <IconButton
                              size="small"
                              onClick={() => handleCopyToClipboard(call.CallSid)}
                              style={{ marginLeft: 8 }}
                            >
                              <ContentCopyIcon fontSize="small" style={{ color: '#fff' }} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                      <TableCell className={classes.tableCell}>{call.CallFrom}</TableCell>
                      <TableCell className={classes.tableCell}>{call.CallTo}</TableCell>
                      <TableCell className={classes.tableCell}>{call.Stream?.Status}</TableCell>
                      <TableCell className={classes.tableCell}>{call.Stream?.Duration}s</TableCell>
                      <TableCell className={classes.tableCell}>
                        <a href={call.Stream?.RecordingUrl} target="_blank" rel="noopener noreferrer">Recording</a>
                      </TableCell>
                      <TableCell className={classes.tableCell}>{new Date(call.Created).toLocaleString()}</TableCell>
                      <TableCell className={classes.tableCell}>
                        <Button variant="contained" color="primary" onClick={() => handleChatOpen(call)}>View Chat</Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box className={classes.paginationContainer}>
              <Pagination
                count={Math.ceil(filteredCalls.length / callsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
              />
            </Box>
          </>
        )
      )}

      <Dialog open={chatModalOpen} onClose={handleChatClose}>
        <DialogTitle>Chat
          {selectedChatExplanation && (
            <Typography variant="body2" style={{ marginTop: '10px', fontStyle: 'italic' }}>
              Explanation: {selectedChatExplanation || 'N/A'}
            </Typography>
          )}
        </DialogTitle>
        <DialogContent dividers>
          {selectedChat.length > 0 ? (
            selectedChat.map((message, index) => {
              const { sender, text } = formatChatMessage(message);
              const isAI = sender === 'AI';
              return (
                <Box
                  key={index}
                  className={classes.chatContainer}
                  style={{ flexDirection: isAI ? 'row' : 'row-reverse' }}
                >
                  <Avatar className={classes.avatar}>
                    {isAI ? 'AI' : 'U'}
                  </Avatar>
                  <Box
                    className={`${classes.chatMessageBox} ${isAI ? classes.aiMessage : classes.clientMessage}`}
                  >
                    <Typography variant="body1">
                      {text}
                    </Typography>
                  </Box>
                </Box>
              );
            })
          ) : (
            <Typography>No chat data available</Typography>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default IncomingCalls;
